.container-login {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    position: relative;

    
}

.caixa-login{
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 1.5rem;
    border-radius: 1.5rem;
    flex-direction: column;
    width: 400px;
    height: 500px;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0.12rem 0.16rem rgb(0, 0, 0), 0 0.24rem 0.32rem rgb(0, 0, 0);
    
}

.caixa-login img {
    padding: 2rem;
    align-self: center;
    width: 350px;
    max-width: 100%;
    margin-bottom: 1.5rem;

}

.buttons-login {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 230px;
    align-self: center;

}

.buttons-login button {
    background-color: var(--primarycolor);
    margin: 0.5rem;
}

.buttons-login button:hover {
    background-color: var(--secundarycolor);
    color: var(--primarycolor)
}

.esquecisenha-login {
    align-self: center;
    
}

#buttonesqueceusenha {
    color: var(--primarycolor);
}